<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Caja - Ver</strong>
          </CCardHeader>
          <CCardBody>

              <b-form id="Form" @submit.prevent="Validate">
                            <b-row>
                                <b-col md="2">
                                    <b-form-group label="Codigo:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.number" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Fecha Inicio:">
                                    <b-form-input readonly type="text" class="text-center" v-model="box.start_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Fecha Final:">
                                    <b-form-input readonly type="text" class="text-center" v-model="box.end_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Usuario:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.user" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Estado:">
                                    <b-form-select :options="states" disabled v-model="box.state" ></b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Saldo Inicial:">
                                    <b-form-input type="text" class="text-right" readonly v-model="box.initial_balance" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                  <b-form-group>
                                    <label class="control-label">Observación: </label>
                                    <b-form-textarea readonly v-model="box.observation" rows="2"></b-form-textarea>                 
                                  </b-form-group>
                                </b-col>

                                <b-col md="6">
                                   <div class="table-responsive">
                                      <table class="table table-hover table-bordered">
                                        <thead>
                                          <tr class="bg-success">
                                            <th width="70%" class="text-center">Modulo</th>
                                            <th width="30%" class="text-right">Monto</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Pagos</td>
                                            <td class="text-right">{{ box.payments }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Aportes</td>
                                            <td class="text-right">{{ box.contributions }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Pagos Anticipados</td>
                                            <td class="text-right">{{ box.advance_payments }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Egresos</td>
                                            <td class="text-right">{{ box.expenses }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Otros</td>
                                            <td class="text-right">{{ box.others }}</td>
                                          </tr>

                                        </tbody>

                                      </table>
                                   </div>
                                </b-col>

                                <b-col md="6">
                                   <div class="table-responsive">
                                      <table class="table table-hover table-bordered">
                                        <thead>
                                          <tr class="bg-success">
                                            <th width="70%" class="text-center">Medio de Pago</th>
                                            <th width="30%" class="text-right">Monto</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Efectivo</td>
                                            <td class="text-right">{{ box.cash }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Cheque</td>
                                            <td class="text-right">{{ box.check }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Deposito de Cuenta</td>
                                            <td class="text-right">{{ box.deposit_account }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Tranferencia de Fondos</td>
                                            <td class="text-right">{{ box.transfer_funds }}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-left pl-1">Saldo Final</td>
                                            <td class="text-right">{{ box.final_balance }}</td>
                                          </tr>
                                        </tbody>

                                      </table>
                                   </div>
                                </b-col>

               
                                <b-col md="3"></b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ModalBoxSummaryShow" class="form-control" variant="info">Resumen de Caja</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ExportExcel" class="form-control" variant="success">Excel</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-link class="btn form-control btn-primary" :to="{ path: '/caja/listar' }" append >REGRESAR</b-link>
                                </b-col>
                            </b-row>
              </b-form>

          
        
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <BoxSummary />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import BoxSummary from './components/Summary'
export default {
  name: "CashEdit",
  components:{
    BoxSummary,
  },
  props: ["id_box"],
  data() {
    return {
      module: "Box",
      role: 3,
      states:[
        {value:'1',text:'Pendiente'},
        {value:'2',text:'Finalizado'},
        {value:'0',text:'Anulado'},
      ],
        box: {
          id_box : '',
          id_user : '',
          number : '',
          start_date : moment(new Date()).local().format("YYYY-MM-DD"),
          end_date : moment(new Date()).local().format("YYYY-MM-DD"),
          observation : '',
          initial_balance : (0).toFixed(2),
          payments : (0).toFixed(2),
          contributions : (0).toFixed(2),
          advance_payments : (0).toFixed(2),
          expenses : (0).toFixed(2),
          others : (0).toFixed(2),
          cash : (0).toFixed(2),
          check : (0).toFixed(2),
          deposit_account : (0).toFixed(2),
          transfer_funds : (0).toFixed(2),
          final_balance : (0).toFixed(2),
          state : '',
      },
    };
  },
  mounted() {
    this.ViewBox();
  },
  methods: {
    ViewBox,
    EditBox,
    Validate,

    ExportExcel,
    ExportPDF,

    ModalBoxSummaryShow

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ExportExcel() {
  let me = this;
  let url = this.url_base + "box-export-excel/"+this.box.id_box;
  window.open(url,'_blank');
}

function ModalBoxSummaryShow() {

  let data = {
    role: this.role,
    id_box: this.box.id_box,
  };
  EventBus.$emit('ModalBoxSummaryShow',data);
  
}

function ExportPDF() {

  let me = this;
  let url = this.url_base + "cash-export-pdf/"+this.cash.id_box+'/'+this.cash.end_date;
  
  window.open(url,'_blank');
}
function ViewBox() {
  let id_box = je.decrypt(this.id_box);
  let me = this;
  let url = this.url_base + "box/view/"+id_box;

  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
          
          me.box.id_box = response.data.result.id_box;
          me.box.id_user = response.data.result.id_user;
          me.box.number = response.data.result.number;
          me.box.start_date = response.data.result.start_date;
          me.box.end_date = response.data.result.end_date;
          me.box.observation = response.data.result.observation;
          me.box.user = response.data.result.user;
          me.box.state = response.data.result.state;

          me.box.initial_balance = response.data.result.initial_balance;
          me.box.payments = response.data.result.payments;
          me.box.contributions = response.data.result.contributions;
          me.box.advance_payments = response.data.result.advance_payments;
          me.box.expenses = response.data.result.expenses;
          me.box.others = response.data.result.others;
          me.box.cash = response.data.result.cash;
          me.box.deposit_account = response.data.result.deposit_account;
          me.box.transfer_funds = response.data.result.transfer_funds;
          me.box.final_balance = response.data.result.final_balance;
          me.box.state = response.data.result.state;
          
      }
    })
}






function EditBox() {
  let me = this;
  let url = this.url_base + "box/edit";
  let data = this.box;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token:this.token,module:this.module,role:this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
         Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
      } else {
         Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  let me = this;
    Swal.fire({
      title: 'Esta seguro de modificar la caja?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Estoy de Acuerdo!'
    }).then((result) => {
      if (result.isConfirmed) {
        me.EditBox();
      }
    })


}


</script>
